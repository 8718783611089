import { HeadProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/Seo';

export default function ComingSoonPage() {
  const registrationOpen = new Date(2021, 9, 9);
  const today = new Date();
  const msToRegistration = Math.abs(
    registrationOpen.valueOf() - today.valueOf()
  );
  const daysToRegistration = Math.floor(
    msToRegistration / (24 * 60 * 60 * 1000)
  );

  return (
    <main className="page-coming-soon">
      <section className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-10 col-lg-offset-1">
            <div className="wrapper">
              <div className="logo">
                <a href="https://www.augustineinstitute.org/" className="logo">
                  <StaticImage
                    src="../images/logo-full.png"
                    alt="Augustine Institute"
                    loading="eager"
                    objectFit="contain"
                    placeholder="none"
                  />
                </a>
              </div>
              <div className="card">
                <div className="card__inner">
                  <h1 style={{ marginBottom: '0.25em' }}>
                    Theology: The Renewal of the Mind
                  </h1>
                  <h2 className="h5">
                    A Virtual Summit for Prospective Students
                  </h2>
                  <div className="separator" />
                  <p className="h4">
                    Registration opens in{' '}
                    <span style={{ display: 'inline-block' }}>
                      <strong>
                        {daysToRegistration}{' '}
                        {daysToRegistration > 1 ? 'days' : 'day'}
                      </strong>
                    </span>{' '}
                    <span style={{ display: 'inline-block', fontSize: '1rem' }}>
                      (
                      {registrationOpen.toLocaleDateString('en-US', {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric',
                      })}
                      )
                    </span>
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 35 35"
                    className="dots"
                  >
                    <circle cx="32.5" cy="32.5" r="2.5" fill="#FDC109" />
                    <circle cx="32.5" cy="22.5" r="2.5" fill="#FDC109" />
                    <circle cx="32.5" cy="12.5" r="2.5" fill="#FDC109" />
                    <circle cx="32.5" cy="2.5" r="2.5" fill="#FDC109" />
                    <circle cx="22.5" cy="32.5" r="2.5" fill="#FDC109" />
                    <circle cx="22.5" cy="22.5" r="2.5" fill="#FDC109" />
                    <circle cx="12.5" cy="22.5" r="2.5" fill="#FDC109" />
                    <circle cx="12.5" cy="32.5" r="2.5" fill="#FDC109" />
                    <circle cx="2.5" cy="32.5" r="2.5" fill="#FDC109" />
                  </svg>
                </div>
                {/* <div className="card__footer" /> */}
              </div>
              <p style={{ marginTop: '1rem' }}>More details coming soon</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export function Head({ location: { pathname } }: HeadProps) {
  return (
    <SEO
      title="Theology: The Renewal of the Mind"
      description="Registration opens in October 9 for a Virtual Summit for Prospective Students"
      pathname={pathname}
    />
  );
}
